html, body {
    margin: 0;
    padding: 0;
}

:root:root {
    --adm-color-primary: #6CB0F7;
}

.adm-capsule-tabs-header {
    border: 0;
}